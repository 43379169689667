import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../lib/interface";
import { setLogout, setUser } from "../app/state";
import NavBar from "../components/application/Navbar";
import axiosService from '../service/axios.service';
import { API_URLS } from "../utils/constants";

export default function AppLayout() {
  const token = useSelector((state: IState): string | undefined => state.token);
  const mode = useSelector((state: IState): string | undefined => state.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUser = async () => {
    try {
      const response = await axiosService.getApiService(API_URLS.USER.USER_PROFILE);
      if (response?.status === 200) {
        const data = response.data.data;
        dispatch(
          setUser({
            id: data.user?.id,
            fullName: data.user?.fullName,
            email: data.user?.email,
          })
        );
      }
    } catch (error: any) {
      dispatch(setLogout())
      navigate("/signin");
    }
  }

  useEffect(() => {
    if (!token) {
      dispatch(setLogout());
      navigate("/signin");
    }
    handleUser();
  }, [token, navigate]);

  useEffect(() => {
    if (mode === 'dark' || (mode === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [mode]);

  return (
    <>
      <div className="flex flex-col min-h-[100vh] bg-secondary text-primary justify-between">
        <Outlet />
      </div>
    </>
  );
}
