import { Dialog } from "@headlessui/react";
import DarkModeToggleButton from "./DarkModeToggleButton";
import { Link } from "react-router-dom";
import { LogoIcon } from "../common/logo";


export default function NavBar() {

    return (
        <header>
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <Link to={'/signin/signin'} className="-m-1.5 p-1.5">
                    <span className="sr-only">Indman</span>
                    <LogoIcon className="h-8 w-auto"/>
                </Link>
                <div className="hidden lg:flex lg:gap-x-12">
                    <DarkModeToggleButton />
                </div>
            </nav>
        </header>
    )
}
