import { useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'
import { MoonIcon, SunIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import { setMode } from '../../app/state'
import { IState } from '../../lib/interface'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function DarkModeToggleButton() {
  const [enabled, setEnabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    
    if(!enabled && document.documentElement.classList.contains('dark')) {
      setEnabled(true);
    }

  }, []);

  useEffect(() => {

    if (enabled) {
      document.documentElement.classList.add('dark');
      dispatch(setMode('dark'))
    } else {
      document.documentElement.classList.remove('dark')
      dispatch(setMode('light'))
    }

  }, [enabled])


  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={classNames(
        enabled ? 'bg-accent focus:ring-muted border-accent' : 'bg-secondary-foreground/20 border-muted-foreground',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer focus:ring-muted rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={classNames(
          enabled ? 'translate-x-5 bg-primary-foreground/80' : 'translate-x-0 bg-primary-foreground',
          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out'
        )}
      >
        <span
          className={classNames(
            enabled ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
          )}
          aria-hidden="true"
        >
          <SunIcon  className="h-4 w-4 text-[#eab308]"></SunIcon>
        </span>
        <span
          className={classNames(
            enabled ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
          )}
          aria-hidden="true"
        >
            <MoonIcon className="h-4 w-4 text-accent"></MoonIcon>
        </span>
      </span>
    </Switch>
  )
}
