import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { IState, IUser } from '../lib/interface';
import { useSelector } from 'react-redux';
import Footer from '../components/auth/Footer';
import NavBar from '../components/auth/Navbar';

export default function AuthLayout() {

  const token = useSelector((state: IState): string | undefined => state.token);
  const user = useSelector((state: IState): IUser | undefined => state.user);
  const mode = useSelector((state: IState): string | undefined => state.mode);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/workspaces");
    }
  }, [token, navigate])

  useEffect(() => {
    if (mode === 'dark' || (mode === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [mode])

  return (
    <>
      <div className="flex flex-col min-h-[100vh] bg-primary-foreground text-primary justify-between sm:px-6 lg:px-8">
        <div className="grow-0">
          <NavBar />
        </div>
        <div className="grow">
          <Outlet />
        </div>
        <div className="grow-0">
          <Footer />
        </div>
      </div>
    </>
  )
}
