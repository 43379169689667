import logoWhite from '../../assets/indman_logo-white.png'
import logoBlack from '../../assets/indman_logo-black.png'
import logo from '../../assets/indman_logo.png'
import { Image } from './image'


export function FullLogoIcon(props: {className: string}) {
    return (
        <>
            <Image src={logoBlack} srcDark={logoWhite} alt="indman_logo" className={props.className} />
        </>
    )
}

export function LogoIcon(props: {className: string}) {

    return (
        <>
            <Image src={logo} alt="indman_logo" className={props.className} />
        </>
    )
}


