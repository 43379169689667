import DarkModeToggleButton from '../../../components/auth/DarkModeToggleButton';
import { FullLogoIcon } from '../../../components/common/logo'
import { SparklesIcon } from "@heroicons/react/24/outline";
import { IState } from '../../../lib/interface';
import { useSelector } from 'react-redux';
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

type TListingItem = 'Invite' | 'Workspace'
interface IWorkspaceUser {
  name: string;
  profilePicture: string;
}

interface IWorkspaceDetails {
  imageURL: string;
  name: string;
  userCount: number;
  users: IWorkspaceUser[];
}

export default function Workspaces() {
  const title = '<span class="text-accent_5 dark:text-accent_2"> Welcome to Indman!</span> Stay hydrated!';
  const subTitle = 'Choose a workspace below to get back to working with your team.';
  const [workspaces, setWorkspaces] = useState<IWorkspaceDetails[]>([
    {
      imageURL: 'https://avatars.slack-edge.com/2022-06-21/3690126583702_9e9db01117dc83bb3ae2_88.png',
      name: 'Zuper Engineering',
      userCount: 1,
      users: [
        {
          name: 'Valliyappan',
          profilePicture: 'https://avatars.slack-edge.com/2021-11-15/2750767285312_596227713873c9382458_48.jpg'
        }
      ],
    },
    {
      imageURL: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQel-EhW24-P2qTkDECExLhv6O8gf1POYTwPBfalSEEPA&s',
      name: 'Lentera',
      userCount: 4,
      users: [
        {
          name: 'Valliyappan',
          profilePicture: 'https://avatars.slack-edge.com/2021-11-15/2750767285312_596227713873c9382458_48.jpg'
        }
      ],
    },
    {
      imageURL: ' https://avatars.slack-edge.com/2023-10-12/6030004931459_7a52ff7bfb385ef3952e_88.png',
      name: 'Indman',
      userCount: 10,
      users: [
        {
          name: 'Valliyappan',
          profilePicture: 'https://avatars.slack-edge.com/2021-11-15/2750767285312_596227713873c9382458_48.jpg'
        }
      ],
    }
  ]);

  const [invitations, setInvitations] = useState<IWorkspaceDetails[]>([
    {
      imageURL: 'https://avatars.slack-edge.com/2022-06-21/3690126583702_9e9db01117dc83bb3ae2_88.png',
      name: 'Zuper Engineering',
      userCount: 1,
      users: [
        {
          name: 'Valliyappan',
          profilePicture: 'https://avatars.slack-edge.com/2021-11-15/2750767285312_596227713873c9382458_48.jpg'
        }
      ],
    },
    {
      imageURL: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQel-EhW24-P2qTkDECExLhv6O8gf1POYTwPBfalSEEPA&s',
      name: 'Lentera',
      userCount: 4,
      users: [
        {
          name: 'Valliyappan',
          profilePicture: 'https://avatars.slack-edge.com/2021-11-15/2750767285312_596227713873c9382458_48.jpg'
        }
      ],
    },
    {
      imageURL: ' https://avatars.slack-edge.com/2023-10-12/6030004931459_7a52ff7bfb385ef3952e_88.png',
      name: 'Indman',
      userCount: 10,
      users: [
        {
          name: 'Valliyappan',
          profilePicture: 'https://avatars.slack-edge.com/2021-11-15/2750767285312_596227713873c9382458_48.jpg'
        }
      ],
    }
  ]);

  useEffect(() => {
    document.title = 'Workspace'
  }, []);

  return (
    <div className="bg-primary-foreground">
      <div className='py-8 animate__animated animate__fadeIn'>

        <div className="mt-6 flex justify-center w-100">
          <FullLogoIcon className="h-10" />
        </div>

        <div className="py-3">
          <h2 className="text-center text-3xl font-bold tracking-tight text-accent py-2" dangerouslySetInnerHTML={{ __html: title }}></h2>
          <p className="lines text-center text-sm text-muted-foreground leading-7" dangerouslySetInnerHTML={{ __html: subTitle }}></p>
        </div>

        <div className="flex flex-col">
          { workspaces && workspaces.length > 0 &&
            <div className="sm:mx-auto sm:w-full sm:max-w-3xl px-4 sm:px-12 space-y-3 py-5 animate__animated animate__fadeIn">
              <WorkspaceListing workspaces={workspaces} />
            </div>
          }
          <div className="grow-0 sm:mx-auto sm:w-full sm:max-w-3xl px-4 sm:px-12 space-y-3 py-5 animate__animated animate__fadeIn">
            <CreateWorkspace workspaces={workspaces} />
          </div>
          {
            invitations && invitations.length > 0 &&
            <div className="sm:mx-auto sm:w-full sm:max-w-3xl px-4 sm:px-12 space-y-3 py-5 animate__animated animate__fadeIn">
              <InvitationListing invitations={invitations} />
            </div>
          }

        </div>

      </div>
    </div>
  )
}

function WorkspaceListing(props: { workspaces: IWorkspaceDetails[] }) {
  const user = useSelector((state: IState) => {
    return state.user
  });
  const workspaces = props.workspaces;
  const context = 'Workspaces for <strong>' + user?.email + '</strong>';

  return (
    <>
      <div className="overflow-hidden shadow sm:rounded-lg border bg-muted dark:border-muted">
        <div className="px-4 py-3 sm:px-6">
          <h3 className="text-bas text-sm leading-7 text-primary" dangerouslySetInnerHTML={{ __html: context }} />
        </div>
        {
          workspaces && workspaces.length > 0 &&
          <div className="border-t dark:border-muted-foreground px-6" >
            <ul role="list" className="divide-y dark:divide-muted-foreground">
              {workspaces.map((workspace, index) => (
                <React.Fragment key={index}>
                  <WorkspaceItem workspace={workspace} />
                </React.Fragment>
              ))}
            </ul>
          </div>
        }
      </div>
    </>
  )
}

function WorkspaceItem(props: { workspace: IWorkspaceDetails }) {
  const workspace = props.workspace;
  return (
    <>
      <li className="flex justify-between items-center gap-x-6 py-3 group hover:bg-muted/30">
        <div className="grow flex min-w-0 gap-x-4">
          <img className="h-12 w-12 flex-none rounded-md bg-secondary-foreground border dark:border-muted-foreground" src={workspace.imageURL} alt="" />
          <div className="min-w-0 flex-auto">
            <p className="text-md font-semibold leading-6">{workspace.name}</p>
            <p className="truncate text-xs leading-5 text-muted-foreground">{workspace.userCount} members</p>
          </div>
        </div>
        <div className="sm:flex sm:flex-col sm:items-end pr-5 group-hover:pr-3 text-primary">

          <p className="hidden animate__animated animate__pulse group-hover:flex mt-1 items-center gap-x-1.5 text-center group-hover:text-accent">
            <span>Open</span>
            <ArrowRightIcon className="h-5" />
          </p>

          <p className="group-hover:hidden flex mt-1 items-center gap-x-1.5 text-center group-hover:text-accent">
            <ArrowRightIcon className="h-5" />
          </p>

        </div>
      </li>
    </>
  )
}

function CreateWorkspace(props: { workspaces: IWorkspaceDetails[] }) {
  const context = props.workspaces.length > 0 ? 'Want to use Indman with a different team?' : 'Create a team';
  const buttonName = props.workspaces.length > 0 ? 'Create another Worksace' : 'Create a Worksace';
  return (
    <>
      <div className="p-4 flex justify-between items-center bg-secondary border-none border rounded text-sm text-muted-foreground space-x-2">

        <div className="grow-0">
          <img src='https://a.slack-edge.com/bv1-10/get-started-workspaces-icon-88e0cb1.svg' className="h-10 grow-0"></img>
        </div>
        <div className="grow">
          <p className="grow text-sm">{context}</p>
        </div>
        <div className="grow-0">
          <Link
            to='new'
            type="button"
            className="inline-flex items-center gap-x-2 text-sm rounded-sm bg-accent text-secondary px-3.5 py-1.5 font-semibold shadow-sm hover:bg-accent-foreground focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-foreground"
          >
            {buttonName}
          </Link>
        </div>
      </div>
    </>
  )
}

function InvitationListing(props: { invitations: IWorkspaceDetails[] }) {
  const user = useSelector((state: IState) => {
    return state.user
  });
  const invitations = props.invitations;
  const title = 'Pending invitations'
  const context = 'Invitations for <strong>' + user?.email + '</strong>';


  return (
    <>
      <h3 className='pt-3 text-lg font-semibold'>{title}</h3>
      <div className="overflow-hidden shadow sm:rounded-lg border bg-muted dark:border-muted">
        <div className="px-4 py-3 sm:px-6">
          <h3 className="text-bas text-sm leading-7 text-primary" dangerouslySetInnerHTML={{ __html: context }} />
        </div>
        {
          invitations && invitations.length > 0 &&
          <div className="border-t dark:border-muted-foreground px-6" >
            <ul role="list" className="divide-y dark:divide-muted-foreground">
              {invitations.map((workspace, index) => (
                <React.Fragment key={index}>
                  <InvitationItem workspace={workspace} />
                </React.Fragment>
              ))}
            </ul>
          </div>
        }

      </div>
    </>
  )
}

function InvitationItem(props: { workspace: IWorkspaceDetails }) {
  const workspace = props.workspace;
  const buttonName = 'Join'
  return (
    <>
      <li className="flex justify-between items-center gap-x-6 py-3 group hover:bg-muted/30">
        <div className="grow flex min-w-0 gap-x-4">
          <img className="h-12 w-12 flex-none rounded-md bg-secondary-foreground border dark:border-muted-foreground" src={workspace.imageURL} alt="" />
          <div className="min-w-0 flex-auto">
            <p className="text-md font-semibold leading-6">{workspace.name}</p>
            <p className="truncate text-xs leading-5 text-muted-foreground">{workspace.userCount} members</p>
          </div>
        </div>
        <div className="sm:flex sm:flex-col sm:items-end pr-5">

          <button
            type="button"
            className="inline-flex items-center gap-x-2 text-sm rounded-sm text-secondary bg-accent px-3.5 py-1.5 font-semibold shadow-sm hover:bg-accent-foreground focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
          >
            {buttonName}
          </button>

        </div>
      </li>
    </>
  )
}