import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { IState } from '../lib/interface';
import { useSelector } from 'react-redux';
import NavBar from '../components/application/Navbar';

export default function WorkspaceLayout() {

  const workspace = useSelector((state: IState) => state.currentWorkspace);
  const navigate = useNavigate();

  useEffect(() => {
    if (workspace?.workspaceName) {
      navigate("/w/" + workspace?.workspaceName + "/dashboard");
    } else {
      navigate("/workspaces");
    }
  }, [workspace, navigate])

  return (
    <>
      <div className="flex flex-col min-h-[100vh] bg-secondary text-primary justify-between sm:px-6 lg:px-8">
        <div className="grow-0">
          {/* <NavBar /> */}
          Workspace Layout
        </div>
        <div className="grow">
          <Outlet />
        </div>
      </div>
    </>
  )
}
