import React from "react";
import axiosService from '../../service/axios.service';
import googleIcon240 from '../../assets/google-240.png';
import apple250 from '../../assets/apple-250.png';
import microsoft240 from '../../assets/microsoft-240.png';
import qrBlack from '../../assets/qr-black-icon.png';

import { SparklesIcon } from "@heroicons/react/24/outline";
import { Formik, Form, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IPreAuth } from "../../lib/interface";
import { setToken } from "../../app/state";
import { FullLogoIcon } from "../../components/common/logo";
import { API_URLS } from "../../utils/constants";
import { Image } from "../../components/common/image";

export interface ISignInForm {
  email: string;
}

export interface IOtpFormData {
  textIndex01: string;
  textIndex02: string;
  textIndex03: string;
  textIndex04: string;
  textIndex05: string;
  textIndex06: string;
}

export interface IOtpForm {
  otp: string[]
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function SignIn() {

  const { type } = useParams();
  const navigate = useNavigate();
  const [preAuth, setPreAuth] = useState<IPreAuth>({});

  useEffect(() => {
    document.title = "Login | Indman";

    if (
      !['signin', 'confirmemail'].includes(type || '') ||
      (type === 'confirmemail' && !preAuth?.email)
    ) {
      navigate('/signin/signin')
    }

  }, [type])

  return (
    <>
      {type === "signin" && <SignInPage setPreAuth={setPreAuth}/>}
      {type === "confirmemail" && preAuth?.email && <ConfirmEmailPage preAuth={preAuth} setPreAuth={setPreAuth} />}
    </>
  )
}

function SignInPage(props: {
  setPreAuth: React.Dispatch<React.SetStateAction<IPreAuth>>
}) {

  const navigate = useNavigate();
  const signUpTextBoxRef = useRef<any>(null);

  const setPreAuth = props.setPreAuth;
  const title = "Sign in to Indman for free";
  const subTitle = "We suggest using the <strong> email address you use at work </strong>";
  const submitButtonName = "Sign In With Email";
  const postBody = "We’ll email you a magic code for a password-free sign in.";

  const Auth0Buttons = [
    {
      label: 'Continue with Google',
      image: googleIcon240,
      action: () => {
        console.log("'Continue with Google' Clicked")
      }
    },
    {
      label: 'Continue with Microsoft',
      image: microsoft240,
      action: () => {
        console.log("'Continue with Microsoft' Clicked")
      }
    },
    {
      label: 'Continue with Apple',
      image: apple250,
      action: () => {
        console.log("'Continue with Apple' Clicked")
      }
    }
  ];

  let joinFormData: ISignInForm = {
    email: '',
  };

  const handleSubmit = async (formData: ISignInForm, { resetForm }: any) => {
    try {
      const payload = {
        email: formData.email?.trim(),
      }

      const response = await axiosService.postApiService(API_URLS.AUTH.SENT_OTP, payload);

      if (response?.status === 200) {
        resetForm();
        setPreAuth(payload);
        navigate('/signin/confirmemail');
      }

      // Handle for error

    } catch (err: any) {

    }
  }

  const validate = (values: ISignInForm) => {
    let errors: any = {};

    // eslint-disable-next-line
    const emailRegexExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!emailRegexExp.test(values.email)) {
      errors.email = "Invalid email formart";
    }

    return errors;

  }

  useEffect(() => {
    signUpTextBoxRef.current.focus();
    setPreAuth({})
  }, [])

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-lg px-4 sm:px-6 animate__animated animate__fadeIn">

        <div className="mt-6 flex justify-center w-100">
          <FullLogoIcon className="h-8" />
        </div>
        <h2 className="mt-3 text-center text-3xl font-bold tracking-tight text-accent">{title}</h2>
        <p className=" text-center text-md py-4 text-muted-foreground" dangerouslySetInnerHTML={{ __html: subTitle }}>
        </p>
      </div>

      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md px-4 sm:px-6 space-y-3 animate__animated animate__fadeIn">
        {Auth0Buttons.map(buttonData => {
          return <div key={buttonData.label}>
            <button
              className="flex w-full bg-secondary justify-center items-center rounded-md border-2 py-2 px-4 focus:outline-none focus:ring-0"
              onClick={buttonData.action}>
              <Image src={buttonData.image} alt={buttonData.label} className="h-6 px-3" />
              <span className="text-md font-medium text-primary">
                {buttonData.label}
              </span>
            </button>
          </div>
        })}
      </div>

      <div className="px-4 sm:px-6 py-6">
        <div className="relative sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t dark:border-muted-foreground" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-primary-foreground px-2 text-sm text-muted-foreground">OR</span>
          </div>
        </div>
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-md px-4 sm:px-6 animate__animated animate__fadeIn">

        <Formik
          initialValues={joinFormData}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            submitCount
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit} className="space-y-5">
              <div>
                <div className="relative mt-1 rounded-md">
                  <input
                    id="signin"
                    ref={signUpTextBoxRef}
                    type="email"
                    name="email"
                    autoComplete="username"
                    placeholder="name@work-email.com"
                    className={"block w-full rounded-md focus:outline-none sm:text-md border-2 px-3 py-2 bg-secondary" + classNames(
                      errors.email && submitCount > 0 ? " border-destructive pr-10 text-error_9 placeholder:text-destructive focus:border-destructive focus:ring-destructive" : " border placeholder:text-muted-foreground shadow-sm focus:border-accent focus:ring-accent"
                    )
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />

                </div>
                {
                  errors.email &&
                  submitCount > 0 &&
                  <p className="mt-1 text-sm text-destructive" id="email-error">
                    {errors.email}
                  </p>
                }
              </div>

              <div>
                <button type="submit" className="flex w-full justify-center rounded-md border-2 border-transparent bg-accent  py-2 px-4 text-md font-medium text-secondary shadow-sw focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2" disabled={isSubmitting}>
                  {submitButtonName}
                </button>
              </div>


              <div className="pb-6">
                <div className="p-4 bg-secondary border rounded text-sm text-muted-foreground space-x-2">

                  <SparklesIcon className="h-4 w-4 inline text-gold"></SparklesIcon>
                  <p className="inline">{postBody}</p>

                </div>
              </div>

            </Form>

          )}
        </Formik>

      </div>
    </>
  )
}

function ConfirmEmailPage(props: {
  preAuth: IPreAuth, 
  setPreAuth: React.Dispatch<React.SetStateAction<IPreAuth>>
}) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorResponse, setErrorResponse] = useState<boolean>(false);

  const initialValue: IOtpForm = {
    otp: Array(6).fill("")
  }

  const preAuth = props.preAuth;
  const setPreAuth = props.setPreAuth;
  const title = "Check your email for a code";
  const subTitle = "We’ve sent a 6-character code to <strong>" + preAuth?.email + "</strong>. The code expires shortly, so please enter it soon.";
  const inputRef = useRef<any>({});
  const postBody = "Can’t find your code? Check your spam folder!";
  const mailOptions = [
    {
      imageURL: 'https://img.icons8.com/fluency/48/gmail-new.png',
      label: 'Open Gmail',
      href: 'https://mail.google.com/mail/u/0/',
    }, {
      imageURL: 'https://img.icons8.com/fluency/48/microsoft-outlook-2019.png',
      label: 'Open Outlook',
      href: 'https://www.microsoft.com/en-us/microsoft-365/outlook/log-in',
    }
  ]

  let animate_repeat_count = 1;

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async () => { }
  })

  const handleOnChange = (event: any, index: number) => {
    const { value } = event.target;
    const currentOtp = [...formik.values.otp];
    if (
      !(/[a-zA-Z0-9]/gi.test(value.slice(-1).toUpperCase()))
      && value !== ''
    ) {
      return
    };
    currentOtp[index] = value.slice(-1).toUpperCase();
    formik.setValues((prev) => ({
      ...prev,
      otp: currentOtp,
    }));
    if (value && index < 5) {
      inputRef.current[index + 1].focus();
    } else {
      // handleSubmit()
    }
  }

  const handleBackspace = (event: any, index: number) => {
    if (event.key === "Backspace" && index > 0) {
      inputRef.current[index - 1].focus()
    }

    const currentOtp = formik.values.otp.join('')

    if (index === 5 && currentOtp.length === 6) {
      handleSubmit(currentOtp)
    }
  }

  const handleSubmit = async (otp: string) => {
    try {

      const payload = {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase(),
        email: preAuth?.email,
        otp: otp
      }
  
      // console.log(payload)
      const response = await axiosService.postApiService(API_URLS.AUTH.VERIFY_OTP, payload);
  
      if (response?.status === 200) {
        setErrorResponse(false);
        setPreAuth({});
        // console.log(response.data.data.access_token)
        dispatch(setToken(response.data.data.access_token));
        navigate('/workspaces');
      } else {
        setErrorResponse(true);
      }
    } catch {
      setErrorResponse(true);
    }

   

    // Handle for error
  }

  const pasteText = (event: any) => {
    const pastedText = event.clipboardData?.getData("text");
    const fieldValue: any = [];

    pastedText.split('').map((_: any, index: number) => {
      const value = pastedText[index].toUpperCase();
      if (fieldValue.length <= 5 && ((/[a-zA-Z0-9]/gi.test(value)) || value === '')) {
        fieldValue.push(value)
      }
    });

    formik.setValues((prev) => ({
      ...prev,
      otp: fieldValue,
    }));

    inputRef.current[5].focus();

    // setOtp(fieldValue);
  }

  useEffect(() => {
    inputRef.current[0].focus()
    inputRef.current[0].addEventListener("paste", pasteText);
    () => inputRef.current[0].removeEventListener("paste", pasteText);
  }, []);

  const renderInput = () => {
    const renderInputClass = (index: number) => {

      let baseClass = "w-1/3 text-3xl text-center border border-secondary-foreground uppercase py-4 md:py-6 rounded-none focus:z-10 focus:ring-2 focus:ring-inset focus:outline-none"
      let roundedClass = '';
      let borderClass = ' ';
      let textColorClass = 'text-primary';
      let bgClass = 'bg-muted ';
      let focusClass = "focus:ring-accent"
      let animate = '';

      switch (index % 3) {
        case 0: roundedClass = "rounded-l-md border-r-0"; break;
        case 2: roundedClass = "rounded-r-md  border-l-0"; break;
        default: break;
      }

      if (errorResponse) {
        borderClass = "border-destructive";
        textColorClass = 'text-destructive';
        bgClass = 'bg-destructive/10';
        focusClass = 'focus:ring-destructive';
        animate = 'animate__animated animate__shakeX'
      }

      return [
        baseClass,
        bgClass,
        roundedClass,
        borderClass,
        textColorClass,
        focusClass,
        animate
      ].join(' ')
    }

    return <div className="flex justify-between items-center">
      {
        formik.values.otp.map((value: any, index) => {
          return <React.Fragment key={index}>
            <input
              value={value}
              ref={(element) => inputRef.current[index] = element}
              className={renderInputClass(index)}
              name={index.toString()}
              type="text"
              onChange={(e) => handleOnChange(e, index)}
              onKeyUp={(e) => handleBackspace(e, index)}
            />
            {
              index === 2 &&
              <span className="px-3 text-muted-foreground">-</span>
            }
          </React.Fragment>
        })
      }
    </div>
  }

  return (
    <div>
      <div className="sm:mx-auto sm:w-full sm:max-w-2xl px-4 sm:px-6">

        <div className="mt-6 flex justify-center w-100">
          <FullLogoIcon className="h-8" />
        </div>

        <h2 className="mt-3 text-center text-3xl font-bold tracking-tight text-accent">{title}</h2>
        <p className=" text-center text-md py-4 text-primary" dangerouslySetInnerHTML={{ __html: subTitle }} />

        <div className="sm:mx-auto flex sm:w-full sm:max-w-md px-4 sm:px-2 py-9">

          <Formik initialValues={initialValue} onSubmit={() => { }}>
            {
              renderInput()
            }
          </Formik>

        </div>

        <div className="sm:mx-auto flex sm:w-full sm:max-w-md px-4 sm:px-2 text-primary ">
          <div className=" text-center w-full">
            <ul className="mx-auto max-w-sm px-6 py-4 md:flex md:items-center md:justify-between lg:px-8">
              {mailOptions.map((item, index) => {
                return <React.Fragment key={index}>
                  <li className="mt-8 w-full md:mt-0">
                    <Link to={item.href} className="flex space-x-2 justify-center items-center text-sm leading-5 hover:underline hover:text-primary" target="_blank">
                      <img src={item.imageURL} alt={item.label} className="h-6" />
                      <span>
                        {item.label}
                      </span>
                    </Link>
                  </li>
                </React.Fragment>
              })}
            </ul>
            <p className="text-md">{postBody}</p>
          </div>
        </div>
      </div>
    </div>
  )
}