import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { IState } from '../lib/interface';
import { useSelector } from 'react-redux';
import NavBar from '../components/application/Navbar';

export default function SettingsLayout() {

  const [auth, mode] = useSelector((state: IState) => [state.token, state.mode]);
  const navigate = useNavigate();

  useEffect(() => {
    // if (auth) {
    //   navigate("/workspaces");
    // }
  }, [auth, navigate])

  useEffect(() => {
    if (mode === 'dark' || (mode === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [mode])

  return (
    <>
      <div className="flex flex-col min-h-[100vh] bg-secondary text-primary justify-between sm:px-6 lg:px-8">
        <div className="grow-0">
          <NavBar />
        </div>
        <div className="grow">
          <Outlet />
        </div>
      </div>
    </>
  )
}
