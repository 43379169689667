import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { IState } from '../lib/interface';
import { useSelector } from 'react-redux';

export default function SiteLayout() {
  return (
    <main>
      <Outlet />
    </main>
  )
}
