import React from "react"
import { Link } from "react-router-dom"

export default function Footer() {

    // const footerItems = [
    //     'Privacy & Terms',
    //     'Contact us'
    // ]

    const footerItems = [
        {
            name: 'Privacy & Terms',
            href: '/privacy_and_terms'
        },
        {
            name: 'Contact us',
            href: '/contact_us'
        },
    ]

    return (
        <footer className="bg-primary-foreground flex-none w-full">
            <ul className="mx-auto max-w-md px-6 py-6 md:flex md:items-center md:justify-between lg:px-8">
                {footerItems.map((item, index) => {
                    return <React.Fragment key={index}>
                        <li className="mt-8 w-full md:mt-0 text-center text-sm leading-5 text-muted-foreground ">
                            <Link to={item.href} >
                                {item.name}
                            </Link>
                        </li>
                    </React.Fragment>
                })}
            </ul>
        </footer>
    )
}
