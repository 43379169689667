import axios from "axios";

const axiosApi = axios.create({
    baseURL: 'http://localhost:4000/api/'
});

const getConfig = (payload?: any, formData?: any) => {
    const access_token = localStorage.getItem("access_token");
    const headers: any = {
        'Content-Type': formData ? 'multipart/form-data' : 'application/json',
    }
    if (access_token) {
        headers['Authorization'] = 'Bearer ' + access_token
        headers['x-tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase();
    }

    return {
        headers: headers
    }
}

const postApiService = async (endpoint: string, payload?: any, params?: any, formData?: any) => {
    let config: any = getConfig(payload, formData);
    if (params) {
        config['params'] = params
    }
    return await axiosApi.post(endpoint, (formData ? formData : (payload || {})), config)
}

const putApiService = async (endpoint: string, payload?: any, params?: any) => {
    let config: any = getConfig();
    if (params) {
        config['params'] = params
    }
    return await axiosApi.put(endpoint, payload, config)
}

const deleteApiService = async (endpoint: string, payload: any, params?: any) => {
    let config: any = getConfig();
    if (params) {
        config['params'] = params
    }
    return await axiosApi.delete(endpoint, config)
}

const getApiService = async (endpoint: string, params?: any) => {
    let config: any = getConfig();
    if (params) {
        config['params'] = params
    }
    return await axiosApi.get(endpoint, config)
}

const turncateName = (text: string, characterLimit: number): string => {
    if (text.length > characterLimit) {
        text = text.substring(0, characterLimit) + "\u2026";
    }
    return text;
}

// eslint-disable-next-line
export default {
    postApiService,
    putApiService,
    getApiService,
    deleteApiService,
    turncateName,
}