export const API_URLS = {
    // AUTH
    AUTH: {
        SENT_OTP: '/auth/send_otp',
        VERIFY_OTP: '/auth/verify_otp',
        LOGOUT_URL: '/auth/logout',
    },
    // USER
    USER: {
        USER: '/user',
        USER_PROFILE: '/user/profile',
    }
}
