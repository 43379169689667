/* eslint-disable prettier/prettier */
import { createSlice } from "@reduxjs/toolkit"
import { IPreAuth, IState, IUser, TModeType } from "../lib/interface";

const initialState: IState = {
  mode: "auto",
  token: undefined,
}

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state, action: { payload: TModeType, type: any }) => {
      state.mode = action.payload;
    },
    setLogout: (state) => {
      state.token = undefined;
      state.user = undefined;
      localStorage.removeItem("access_token");
    },
    setToken: (state, action: { payload: string }) => {
      state.token = action.payload;
      localStorage.setItem("access_token", action.payload);
    },
    setUser: (state, action: { payload: IUser }) => {
      state.user = action.payload;
    },
  } 
});

export const {
  setMode,
  setToken,
  setLogout,
  setUser,
} = globalSlice.actions

export default globalSlice.reducer
