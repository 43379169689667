export function Image(props: { src: string; srcDark?: string; alt?: string; className: string }) {
    return (
        <>
            {props.srcDark 
                ? <>
                    <img src={props.srcDark} alt={props.alt} className={"hidden dark:block " + props.className} />
                    <img src={props.src} alt={props.alt} className={"dark:hidden block " + props.className} />
                </> 
                : <>
                    <img src={props.src}  alt={props.alt} className={props.className} />
                </> 
            }
        </>
    )
}
