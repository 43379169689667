import { Routes, Route, Navigate, redirect } from "react-router-dom";
import AuthLayout from "../layout/AuthLayout";
import SignIn from "../pages/Auth/SignIn";
import SiteLayout from "../layout/SiteLayout";
import LandingPage from "../pages/Site/LandingPage";
import NoPage from "../pages/NoPage";
import AppLayout from "../layout/AppLayout";
import Workspaces from "../pages/App/Workspace/Workspace";
import WorkspaceLayout from "../layout/WorkspaceLayout";
import SettingsLayout from "../layout/SettingsLayout";
import Dashboard from "../pages/App/Workspace/Dashboard/Dashboard";
import WorkspacesNew from "../pages/App/Workspace/WorkspaceNew";
import Privacy from "../pages/Site/Privacy";
import ContactUs from "../pages/Site/ContactUs";

export default function Router() {
  return (
    <Routes>

      <Route path="/" element={<SiteLayout />}>
        <Route path="/privacy_and_terms" element={<Privacy />} />
        <Route path="/contact_us" element={<ContactUs />} />

      </Route>

      <Route path="/" element={<AppLayout />}>
        <Route path="workspaces" element={<Workspaces />} />
        <Route path="workspaces/new" element={<WorkspacesNew />} />
        <Route path="settings" element={<SettingsLayout />} >
          <Route path="userprofile" element={<Workspaces />} />
          <Route path="apiKeys" element={<Workspaces />} />
        </Route>
        <Route path="w/:workspace" element={<WorkspaceLayout />} >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<Workspaces />} />
          <Route path="inviate" element={<Workspaces />} />
        </Route>
      </Route>

 
      <Route path="/" element={<AuthLayout />}>
        <Route path="/signin" element={<Navigate to="/signin/signin" replace />} />
        <Route path="/signin/:type" element={<SignIn />} />
      </Route>

    </Routes>
  );
}
